import { Component } from "react";

class DisplayProp extends Component {
  constructor(props) {
    super(props);
    window.addEventListener("resize", () => {
      props.setState({
        IsMobile: window.innerHeight > window.innerWidth * 0.75 ? true : false
      });
    });
  }

  IsMobile() {
    return window.innerHeight > window.innerWidth * 0.75 ? true : false;
  }
}

export default DisplayProp;
