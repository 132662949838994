import React from "react";
import Header from "./scripts/Header.js";
import HomeApp from "./scripts/HomeApp.js";
import Footer from "./scripts/Footer.js";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      {(() => {
        switch (1) {
          case 1:
            return <HomeApp />;
          default:
            break;
        }
      })()}
      <Footer />
    </div>
  );
}

export default App;
