import React, { Component } from "react";
import logo from "../images/logo.svg";
import "../styles/Header.css";

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <img src={logo} className="Logo" alt="logo" />
      </header>
    );
  }
}

export default Header;
