import React, { Component } from "react";
import DisplayProp from "../scripts/DisplayProp";
import stream_teams from "../images/stream_teams.svg";
import contestants from "../images/contestants.svg";
import "../styles/HomeApp.css";

class HomeApp extends Component {
  constructor(props) {
    super(props);
    this.display = new DisplayProp(this);
    this.state = {
      currentTab: 0
    };
  }
  async componentDidMount() {}

  async componentWillUnmount() {}

  SetTab(value) {
    this.setState({ currentTab: value });
  }

  LoginForum = () => {
    return (
      <div className="LoginForum">
        <div
          className={this.display.IsMobile() ? "mInput-label" : "Input-label"}
        >
          Email
        </div>
        <div
          className={
            this.display.IsMobile() ? "mInput-container" : "Input-container"
          }
        >
          <input className="Input-object" type="text" />
        </div>
        <div
          className={this.display.IsMobile() ? "mInput-label" : "Input-label"}
        >
          Password
        </div>
        <div
          className={
            this.display.IsMobile() ? "mInput-container" : "Input-container"
          }
        >
          <input className="Input-object" type="password" />
        </div>
        <div
          className={this.display.IsMobile() ? "mInput-label2" : "Input-label2"}
        >
          <input type="checkbox" />
          Remember Me
        </div>
        <div className="Submit-container">
          <button className="Input-button">LOGIN</button>
        </div>
      </div>
    );
  };

  RegisterForum = () => {
    return (
      <div className="RegisterForum">
        <div
          className={this.display.IsMobile() ? "mInput-label" : "Input-label"}
        >
          Community Moniker
        </div>
        <div
          className={
            this.display.IsMobile() ? "mInput-container" : "Input-container"
          }
        >
          <input className="Input-object" type="text" />
        </div>
        <div
          className={this.display.IsMobile() ? "mInput-label" : "Input-label"}
        >
          Email
        </div>
        <div
          className={
            this.display.IsMobile() ? "mInput-container" : "Input-container"
          }
        >
          <input className="Input-object" type="text" />
        </div>
        <div
          className={this.display.IsMobile() ? "mInput-label" : "Input-label"}
        >
          Verify Email
        </div>
        <div
          className={
            this.display.IsMobile() ? "mInput-container" : "Input-container"
          }
        >
          <input className="Input-object" type="text" />
        </div>
        <div
          className={this.display.IsMobile() ? "mInput-label" : "Input-label"}
        >
          Password
        </div>
        <div
          className={
            this.display.IsMobile() ? "mInput-container" : "Input-container"
          }
        >
          <input className="Input-object" type="password" />
        </div>
        <div
          className={this.display.IsMobile() ? "mInput-label" : "Input-label"}
        >
          Confirm Password
        </div>
        <div
          className={
            this.display.IsMobile() ? "mInput-container" : "Input-container"
          }
        >
          <input className="Input-object" type="password" />
        </div>
        <div
          className={this.display.IsMobile() ? "mInput-label2" : "Input-label2"}
        >
          <input type="checkbox" />I agree to{" "}
          <a href="#RegisterForum">Terms of Service</a>
        </div>
        <div className="Submit-container">
          <button className="Input-button">REGISTER</button>
        </div>
      </div>
    );
  };

  render() {
    const LoginTab = 0;
    const RegisterTab = 1;

    return (
      <div className="HomeApp">
        <div
          className={
            this.display.IsMobile() ? "mContainer-left" : "Container-left"
          }
        >
          <div className="Container-line">
            <img
              src={stream_teams}
              className={
                this.display.IsMobile() ? "mHeader-images" : "Header-images"
              }
              alt="stream teams"
            />
          </div>
          <div
            className={
              this.display.IsMobile() ? "mContainer-line" : "Container-line"
            }
          >
            Do you or your stream team want to sponser a contest? Using our free
            raffle ticket system you and members of your stream team can
            organize and manage contest for all of your viewers. Sign up for a
            Stream Team account today to begin your first contest and start
            handing out those raffle tickets!
          </div>
          <div className="Container-line">
            <br />
          </div>
          <div className="Container-line">
            <img
              src={contestants}
              className={
                this.display.IsMobile() ? "mHeader-images" : "Header-images"
              }
              alt="viewers"
            />
          </div>
          <div
            className={
              this.display.IsMobile() ? "mContainer-line" : "Container-line"
            }
          >
            Your favorite streamer or stream teams is sponsering a contest. Make
            sure you keep watching thier channels for chance to grab more
            tickets. When you get a ticket for a contest come here to claim it
            and join the raffle. The more tickets you collect the more chances
            there are to win. See individual contest rules for more details.
          </div>
        </div>
        <div
          className={
            this.display.IsMobile() ? "mContainer-center" : "Container-center"
          }
        />
        <div
          className={
            this.display.IsMobile() ? "mContainer-right" : "Container-right"
          }
        >
          <div
            className={
              this.display.IsMobile()
                ? this.state.currentTab === LoginTab
                  ? "mLoginBtnSelected"
                  : "mLoginBtn"
                : this.state.currentTab === LoginTab
                ? "LoginBtnSelected"
                : "LoginBtn"
            }
            onClick={() => this.SetTab(LoginTab)}
          >
            LOGIN
          </div>
          <div
            className={
              this.display.IsMobile()
                ? this.state.currentTab === RegisterTab
                  ? "mRegisterBtnSelected"
                  : "mRegisterBtn"
                : this.state.currentTab === RegisterTab
                ? "RegisterBtnSelected"
                : "RegisterBtn"
            }
            onClick={() => this.SetTab(RegisterTab)}
          >
            REGISTER
          </div>
          {(() => {
            switch (this.state.currentTab) {
              case LoginTab:
                return this.LoginForum();
              case RegisterTab:
                return this.RegisterForum();
              default:
                break;
            }
          })()}
        </div>
      </div>
    );
  }
}

export default HomeApp;
